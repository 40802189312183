/* VideoLoader.css */

.video-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 23431221343445;
}

.video-loader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-loader.loading {
  display: block;
}

.video-loader img {
  width: 100%;
  height: 100%;
}
