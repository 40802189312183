.login_page {
  /* margin-top: 100px; */
  padding: 20px;
  background-image: url("../../assets/reg1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -400px;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_page form {
  width: min(500px,100%);
  max-width: 100%;
  background-color: white;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.login_page h4 {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-size: 22px;
}

.login_page form .pass_div {
  position: relative;
}

.login_page form .pass_div svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
}

.login_page form label {
  margin-bottom: 10px;
}

.login_page form .pass_div input {
  width: 100%;
  padding: 10px;
}

.login_page h4 span:nth-child(2) {
  color: #30a79d;
  cursor: pointer;
}

.login_page button {
  background-color: #30a79d;
  color: white;
  width: 100px;
  max-width: 100%;
  display: block;
  margin: 10px auto;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width:1300px) {
  .login_page {
    background-position-y: -300px !important;
  }
}

@media (max-width:1200px) {
  .login_page {
    background-position-y: -100px !important;
  }
}

@media (max-width:992px) {
  .login_page {
    background-position-y: -200px;
  }
}

@media (max-width:767px) {
  .login_page {
    background-position-y: -30px !important;
  }
}

@media (max-width:565px) {
  .login_page {
    padding: 10px;
  }
}
