.circle {
    width: 180px;
    height: 27px;
    border :1px solid #597B82;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle div {
    position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width:900px) {
  .circle {
    width: 95px;
  }
}


@media (max-width:860px) {
  .circle {
    width: 100px;
    height: 27px;
  }
}

@media (max-width:810px) {
  .circle {
    width: 110px;
    height: 28px;
  }

  .circle div {
    width: 21px;
    height: 21px;
  }
}

@media (max-width:775px) {
  .circle {
    width: 130px;
  }
}

@media (max-width:720px) {
  .circle {
    width: 140px;
  }
}

@media (max-width:682px) {
  .circle {
    width: 160px;
  }
}


@media (max-width:652px) {
  .circle {
    width: 190px;
  }
}

@media (max-width:600px) {
  .circle {
    width: 160px;
  }
}


@media (max-width:532px) {
  .circle {
    width: 180px;
  }
}