/* MyModal.css */

/* Modal overlay */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal content */
.modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 500px;
  animation: fadeIn 0.3s ease-out;
}

/* Close button */
.close {
  color: #aaa;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

/* Modal title */
.modal-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

/* Modal body */
.modal-body {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

/* Modal actions */
.modal-actions {
  text-align: right;
  margin-top: 20px;
}

.modal-actions button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.modal-actions button:hover {
  background-color: #0056b3;
}

/* Fade in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Show modal */
.show {
  display: block;
}
