.courseintrovideo {
  /* margin-top: -150px; */
}

.courseintrovideo .courseintrovideo_image {
  background-color: #d2d2d2;
  height: 200px;
  position: relative;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.courseintrovideo_image img {
  width: 70px;
  cursor: pointer;
}

.courseintrovideo h4 {
  text-align: center;
  margin: 10px 0px;
  font-weight: 800;
  font-size: 20px;
}

.courseintrovideo .videos_num {
  display: flex;
  align-items: center;
  gap: 10px;
}

.courseintrovideo>div {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 0px 0px 4px 4px;
}

.courseintrovideo>div>div {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  gap: 10px;
}

.courseintrovideo>div>div>* {
  margin: 0px;
}

.courseintrovideo>div>div img {
  width: 20px;
}

.courseintrovideo>div .actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.courseintrovideo>div .actions .heart {
  background-color: var(--main-color);
  color: white;
  padding: 4px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}

.courseintrovideo>div .actions .share {
  background-color: #007bff;
  color: white;
  padding: 4px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}

.course_cart {
  background-color: #218838;
  color: white;
  padding: 4px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width:992px) {
  .courseintrovideo {
    margin-top: -0px;
  }
}

.add_code {
  position: relative;
}

.add_code input {
  padding: 4px 10px;
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.add_code img {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.add_code .spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
  position: absolute;
  right: 10px;
  font-size: 10px;
  width: 10px;
  height: 10px;
  top: 50%;
  /* transform: translateY(-50%); */
}