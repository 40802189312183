footer ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.footer-section {
  background: var(---second-color);
  position: relative;
  /* z-index: -1; */
  padding-top: 50px;
  /* background-image: linear-gradient(
    to right,
    rgb(242, 112, 156),
    rgb(255, 148, 114)
  ); */
  background-color: var(---second-color) !important;
}
.footer-content.pt-5.pb-5 {
  padding: 0 !important;
}

.footer-content {
  margin-top:30px !important;
}

.footer-cta {
  border-bottom: 1px solid var(---second-color);
}

.single-cta i {
  color: var(--main-color);
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #fff;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text {
  display : flex ;
  flex-direction: column;
  gap: 10px ;
}



.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #fff;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3b5998;
}

.twitter-bg {
  background: #55acee;
}

.google-bg {
  background: #dd4b39;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: var(--main-color);
}

footer a {
  text-decoration: none;
}

.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: var(--main-color);
}
a:hover {
}
.footer-widget ul li a {
  color: #fff;
  text-transform: capitalize;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: var(--main-color);
  padding: 13px 20px;
  border: 1px solid var(--main-color);
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  /* border-top: 1px solid #ccc; */
  /* background-image: linear-gradient(
  to right,
  rgb(242, 112, 156),
  rgb(255, 148, 114)
); */
  /* background-color: var(---second-color); */
  /* background: #202020; */
  /* padding: 25px 0; */
  background-color: var(---second-color) !important;
  padding-bottom: 90px;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}

.copyright-text p a {
  color: white;
  /* color: var(--main-color); */
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: var(--main-color);
}

.footer-menu li a {
  font-size: 14px;
  color: #fff;
}

a {
  text-decoration: none;
}

.footer_top {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.footer_top img {
  width: 50px;
}

@media (max-width: 767px) {
  .copyright-area {
    /* margin-bottom: 50px; */
  }
}

.center a {
  position: fixed;
  top: 0;
  display: inline-block;
  margin: 16px;
  padding: 4px 8px;
  font-size: 8pt;
  background: #d6e0ef;
  color: #4570b4;
}
.center a:hover {
  background: #4570b4;
  color: white;
}
.center {
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.center {
  width: max-content;
  height: max-content;
  font-size: 13pt;
  border: 2px solid white;
  border-radius: 50px;
  margin-bottom: 19px;
  /* overflow: hidden; */
}
.center input {
  font-size: inherit;
  width: 250px;
  padding: 7px 16px;
  margin-right: -1px;
  transition: padding 0.2s;
  border-radius: 15px;
  border: 0;
  outline: none;
  background-color: transparent;
  color: white;
}
.center input::placeholder {
  font-size: 12px;
}
.center input::selection {
  background: #dd4b39;
  color: white;
}
.center button {
  font-size: inherit;
  float: right;
  padding: 7px 46px;
  margin-left: -42px;
  background: var(--main-color);
  color: white;
  transition: padding 0.2s;
  border: 0;
  border-radius: 19px;
  z-index: 123;
}
/* .center button:hover {
  cursor: pointer;
  padding: 16px 24px;
}
.center button:hover ~ input {
  padding: 16px 0 16px 16px;
} */
