.container2 {
  max-width: 1450px !important;
  margin: auto;
}

.columnDiv p {
  color: grey;
}

.columnDiv {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 39px auto 0;
}

.card .main h2 {
  font-size: 25px !important;
  font-weight: 600;
  height: 89px;
  /* font-family: none !important; */
  padding: 10px 0;
  margin-top: 0 !important;
  color: #6d7073;
  margin: 0 !important;
  /* border-bottom: 0.1px solid #80808069; */
}

.card .main h2 + span {
  font-size: 19px !important;
  color: #6d7073;
}
.logoDrImage {
  width: 20px;
}
button.btn.fourth:hover {
  color: white;
  /* border: 0.1px solid var(--main-color); */
}

button.btn.fourth:hover::after {
  /* width: 100%; */
  height: 100%;
}
button.btn.fourth::after {
  content: '';

  background: var(--main-color);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  border-radius: 4px;
  height: 0;
  transition: 0.2s ease-in-out;
}
button.btn.fourth {
  position: relative;
  width: 190px;
  margin: 59px auto 10px;
  padding: 10px;
  border: 0.1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 7px 20px 5px rgb(217 217 217 / 53%);
}

.divAlignCenter {
  margin-top: 20px ;
  /* align-items: center !important; */
}

.footerImage {
  width: 190px;
  height: 60px;
  object-fit: cover;
}

.courses_content.py-3 {
  padding: 0 0 150px 0 !important;
}

.accordion-item {
  margin-bottom: -1px;
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.content_div h4,
.videos .video {
  margin: 0 !important;
  background-color: transparent !important;
  padding: 1rem calc(3rem) 1rem 1.25rem !important;
  font-size: 1rem !important;
  color: #212529;
  cursor: pointer;
}
.h5,
h5 {
  font-size: 1rem !important;
  color: #212529 !important;
}
.accordion-item:first-of-type h4 {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rowDiv {
  display: flex;
  gap: 8px;
}

.videos .video img {
  width: 40px;
  height: 40px;
  cursor: pointer;
  object-fit: cover;
}
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

.videos .video .left span {
  background: var(--main-color);
  color: white;
  font-weight: 600;
  font-family: "Playball";
}

.videos .video {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  width: 90%;
  margin: auto;
  border-radius: 0 !important;
}

.videos.show {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
