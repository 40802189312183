.container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  width: 100%;
  gap: 20px;
}

.video {
  background-color: #3e4143;
  margin-right: 40px;
  /* width: fit-content; */
  /* margin-left: auto;
    margin-right: auto; */
}

.video iframe {
  /* width: 90%; */
  /* margin: auto; */
  /* height: 100%; */
}

.accordian {
}
