.story-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  height: 450px;
  border-radius: 16px;
  background-color: #f5f5f5;
  text-align: left;
  background: linear-gradient(to right, #ef466bc4, #ef466a);
  color: white;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: flex-start;
}

.story-header {
  font-size: 24px;
  font-weight: bold;
  /* margin-bottom: 10px; */
  color: white;
}

.story-content {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: white;
}

.student-info {
  font-size: 16px;
  color: white;
}

.stories_container .swiper-slide,
.stories_container .swiper-wrapper {
  max-height: 460px !important;
}
.stories_container {
  margin: 20px auto !important;
  overflow: hidden;
  width: min(1200px, 100%);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.swiper-scrollbar {
  display: none !important;
}

.story {
  width: min(500px, 100%);
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

@media (max-width: 1200px) {
  .stories_container {
    flex-wrap: wrap;
  }
}

.stars span {
  display: block;
  width: 20px;
}
.stars {
  font-size: 14px;
  display: flex;
  color: yellow;
}

.stars svg {
  fill: yellow !important;
}

span.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: white;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-vertical {
  left: auto !important;
  right: 50px !important;
}


