.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: min(945px, 100%);
  height: 474px;
  margin: 40px auto;
  padding-top: 80px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(61, 159, 255, 0.2);
}
.subscribe__title {
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 18px;
}

.subscribe__copy {
  max-width: 450px;
  text-align: center;
  margin-bottom: 53px;
  line-height: 1.5;
}

.form {
  margin-bottom: 25px;
}

.form__email {
  padding: 20px 25px;
  border-radius: 5px;
  border: 1px solid #cad3db;
  width: 431px;
  font-size: 18px;
  color: #0f121f;
}

.form__email:focus {
  outline: 1px solid var(--main-color);
}

.form__button {
  margin: 0 10px;
  background: var(--main-color);
  padding: 10px;
  border: none;
  width: 244px;
  height: 65px;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  box-shadow: 0 4px 20px var(--main-color);
}

.form__button:hover {
  box-shadow: 0 10px 20px var(--main-color);
}

.notice {
  width: 345px;
}

.greenHover {
  transition: 0.5s ease-in-out;
}

.greenHover:hover {
  background-color: green;
  color: white !important;
}

.greenHover:hover span,
.greenHover:hover a {
  color: white !important;
}
