.cart_page {
  margin: auto;
  max-width: 100%;
  flex-wrap: wrap;
  margin-top: 60px;
  padding: 20px;
  background-image: url("../../assets/cartimg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.cart_content {
  display: flex;
  align-items: start;
  justify-content: space-around;
  width: 100%;
  background-color: #f3f1fe;
  padding: 10px;
  border-radius: 10px;
  width: 1000px;
  max-width: 100%;
  margin: auto;
}

.cart_content>.left {
  width: 60%;
}


.cart_content>.right {
  width: 30%;
}

.cart_course {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  /* align-items: center; */
}

.cart_course .left {
  width: 200px;
  border-radius: 10px;
  height: 200px;
  max-height: 100%;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  height: 100%;
}

.cart_course .left img {
  max-width: 100%;
  overflow: hidden;
  border-radius: 10px;
  height: 200px;
  max-height: 100%;
}


.cart_course .right {
  width: calc(100% - 240px);
}

.cart_course .right>* {
  margin: 0px;
  margin-bottom: 5px;
}

.cart_course .right h4 {
  font-size: 16px;
  font-weight: 800;
}

.cart_course .right p:first-of-type {
  font-size: 12px;
  font-weight: 500;
  color: #555;
}

.cart_course .right p:nth-of-type(2) {
  font-size: 20px;
  font-weight: 900;
}

.cart_course .right img {
  width: 20px;
}

.cart_content .right {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.cart_content .right>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.cart_content .right h4 {
  color: #019346;
}

.cart_content .right>div:nth-of-type(4) {
  border: none !important;
  position: relative;

}

.cart_content .right>div:nth-of-type(4) input {
  width: 100%;
  outline: none;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.cart_content .right>div:nth-of-type(4) img {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.cart_content .right:nth-of-type(2)>div img {
  width: 30px;
  cursor: pointer;
}

@media (max-width:992px) {

  .cart_content {
    flex-direction: column;
  }

  .cart_content>.left {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 10px;
  }

  .cart_content>.right {
    margin: auto;
    width: 60%;
    order: -2;
    margin-bottom: 20px;
  }
}

@media (max-width:767px) {

  /* .cart_course {
    width: 41%;
  } */


  .cart_content {
    flex-direction: column;
  }

  .cart_content>.left {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 10px;
  }

  .cart_content>.right {
    margin: auto;
    width: 50%;
    margin-bottom: 20px;

  }

  .cart_course {
    flex-direction: column;
    width: 49%;
  }

  .cart_course .right {
    width: 100%;
  }

  .cart_course .left {
    width: 100%;
  }

  .cart_course .left img {
    width: 100%;
  }
}

@media (max-width:767px) {
  .cart_page {
    margin-bottom: 30px;
  }
}

@media (max-width:565px) {

  .cart_content>.left,
  .cart_content>.right {
    width: 100%;
  }
}