.registration {
  /* margin-top: 60px; */
  padding: 20px;
  background-image: url("../../assets/reg1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -400px;
  min-height: calc(100vh - 100px) !important;
}

.registration form {
  width: 500px;
  max-width: 100%;
  background-color: white;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.registration h4 {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-size: 22px;
}

.registration form>div {
  margin-bottom: 10px;
}

.registration form label {
  margin-bottom: 10px;
}

.registration h4 span:nth-child(2) {
  color: #30a79d;
  cursor: pointer;
}

.react-tel-input input {
  width: 100%;
}

.registration button {
  background-color: #30a79d;
  color: white;
  padding: 10px;
  width: 100px;
  border: none;
  border-radius: 4px;
  transition: .3s all ease-in-out;
  border: 2px solid transparent;
  display: block;
  margin: auto;
}

.registration button:hover {
  background-color: transparent;
  color: #30a79d;
  border: 2px solid #30a79d;

}

@media (max-width:1300px) {
  .registration {
    background-position-y: -300px !important;
  }
}

@media (max-width:1200px) {
  .registration {
    background-position-y: -100px !important;
  }
}

@media (max-width:992px) {
  .registration {
    background-position-y: -200px;
  }
}

@media (max-width:767px) {
  .registration {
    background-position-x: -50px;
    margin-bottom: 40px;
  }
}

@media (max-width:565px) {
  .registration {
    padding: 10px;
  }

}
