.profilevideo_page {
  margin: 0px 0px 80px 0px;
  min-height: calc(110vh - 160px);
  position: relative;
  flex: 1;
}

.publitioPlaceHolder {
  width: 100%;
  height: 100% !important;
  z-index: 999;
  position: absolute;
  cursor: pointer;
}

iframe {
  width: 100%;
  min-height: 75vh;
}
